<template lang='pug'>
.ExperimentSelected.theme-dark
  .ExperimentSelected-Form
    .ExperimentSelected-Header
      .flex-row.flex-row-justify-between
        div {{ $t('selected-experiments', 'Выбранные эксперименты', { ns: 'experiments' }) }}
        el-button(
          link
          @click="$emit('clear')"
          type="warning"
        ) {{ $t('clear') }} (Esc)
    .ExperimentSelected-Body
      template(
        v-for="exp in orderedExperiments"
      )
        .ExperimentSelected-item(
          :style="{ backgroundColor: tagBackground(exp.color) }"
        )
          .ExperimentSelected-item-name(
            :style="{ borderColor: exp.color }"
          ) {{ exp.title }}

    .mv-15
      .ExperimentSelected-Header
        div {{ $t('selected-experiments-set-color-header', 'Установить цвет для выбранных опытов', { ns: 'experiments' }) }}
      .flex-row.flex-row-align-center.flex-row-justify-between
        ColorGradientPicker(
          v-model="selectedColor"
          :gradients="gradients"
          v-model:active-gradient="_activeGradient"
          @update:active-="setGradient"
          :prefix-colors="['#FBFC02']"
          :count="15"
        )
        .ml-8
          el-popover(
            ref="popoverRef"
            trigger="hover"
            virtual-triggering
            persistent
            :show-after="500"
            placement="top"
            :width="300"
          )
            span {{ $t('selected-experiments-apply-gradient', 'Нажав кнопку применить, опыты будут раскрашены по выбранному градиенту цветов по порядку выбора опытов.', { ns: 'experiments' }) }}
          el-button(
            v-popover="popoverRef"
            v-click-outside="onClickOutside"
            @click='applyPalette'
            type="primary"
          ) {{ $t('apply') }}
    .ExperimentSelected-Info
      div(
        v-html="$t('action-copy', 'Нажав комбинацию кнопок Ctrl+C, вы скопируете выбранные эксперементы во временный буфер. Буфер доступен пока открыто окно \"Добавление опытов на шаблон карты\". Для вставки скопируемых опытов используйте Ctrl+V, Опыты будут вставлены в позицию нахождения курсора на карте.', { ns: 'experiments' })"
      )
</template>

<script lang='ts'>
import { GradientModel } from '@/models/assets/GradientModel';
import {
  computed, defineComponent, PropType, ref, unref, watch,
} from 'vue';
import ColorGradientPicker from '@/components/shared/ColorGradientPicker/ColorGradientPicker.vue';
import { area, polygon } from '@turf/turf';
import round from '@/lib/tools/round';
import ProgressCard from '@/components/ui/ProgressCard/ProgressCard.vue';
import { Delete } from '@element-plus/icons-vue';
import hexToRgba from '@/lib/convertors/hexToRgba';
import { ClickOutside as vClickOutside } from 'element-plus';
import chroma from 'chroma-js';
import { useExperiments } from '@/pages/task-map/create/experiments/composables/useExperiments';

export default defineComponent({
  name: 'ExperimentSelected',
  components: {
    ColorGradientPicker,
    ProgressCard,
  },
  props: {
    gradients: {
      type: Array as PropType<GradientModel[]>,
      required: true,
    },
    activeGradient: {
      type: Object as PropType<GradientModel>,
      required: true,
    },
  },
  emits: ['clear', 'update:activeGradient'],
  setup(props, { emit }) {
    const selectedColor = ref();

    const experimentsOrder = new Set<string>();

    const _activeGradient = ref<GradientModel>(props.activeGradient);

    const {
      refreshMap,
      selectedExperiments,
    } = useExperiments();

    const tagBackground = (color: string) => {
      const c = hexToRgba(color);
      c[3] = 0.15;
      return `rgba(${c.join(',')})`;
    };

    const setGradient = ($event: GradientModel) => {
      emit('update:activeGradient', $event);
    };

    watch(selectedExperiments, () => {
      const uuids = selectedExperiments.value.map((v) => v.uuid);

      experimentsOrder.forEach((value) => {
        if (!uuids.includes(value)) {
          experimentsOrder.delete(value);
        }
      });
      for (let i = 0; i < selectedExperiments.value.length; i++) {
        const value = selectedExperiments.value[i].uuid;
        if (!experimentsOrder.has(value)) {
          experimentsOrder.add(value);
        }
      }
      experimentsOrder.forEach((value) => {
        const exp = selectedExperiments.value.find((v) => v.uuid === value);
        if (!uuids.includes(value)) {
          experimentsOrder.delete(value);
        }
      });
    });

    const orderedExperiments = computed(() => [...selectedExperiments.value].sort((a, b) => Array.from(experimentsOrder).indexOf(a.uuid) - Array.from(experimentsOrder).indexOf(b.uuid)));

    const applyPalette = () => {
      const colors: string[] = [];

      if (props.activeGradient) {
        const stops = [];
        for (let i = 0; i <= selectedExperiments.value.length - 1; i++) {
          stops.push(i / (selectedExperiments.value.length - 1));
        }
        const f = chroma
          .scale(props.activeGradient.positions.map((p) => p.color))
          .domain(props.activeGradient.positions.map((p) => p.position / 100));
        stops.forEach((v) => colors.push(f(v).toString()));
      }
      orderedExperiments.value.forEach((exp, i) => {
        exp.color = colors[i];
      });
      refreshMap();
    };

    watch(selectedColor, (v) => {
      selectedExperiments.value.forEach((exp) => {
        exp.color = v;
      });
      refreshMap();
    });
    watch(_activeGradient, (a) => {
      setGradient(a as GradientModel);
    });

    const popoverRef = ref();
    const onClickOutside = () => {
      unref(popoverRef).popperRef?.delayHide?.();
    };

    return {
      Delete,
      area,
      polygon,
      round,
      tagBackground,
      selectedColor,
      setGradient,
      onClickOutside,
      popoverRef,
      vClickOutside,
      applyPalette,
      orderedExperiments,
      _activeGradient,
    };
  },
});
</script>
<style lang='scss'>
.ExperimentSelected {
  pointer-events: none;
  bottom: 10px;
  left: 62px;
  right: 340px;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  &-Header {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 4px;
  }
  &-Form {
    position: relative;
    text-align: left;
    pointer-events: all;
    width: 100%;
    max-width: 480px;
    padding: 15px 10px;
    border-radius: 15px;
    box-shadow: 5px 0 10px rgba(0, 0, 0, .35);
    background: var(--color-black-500);
  }
  &-Body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
  &-item {
    &-name {
      border-left: 4px solid;
      padding: 2px 6px;
      border-radius: 3px;
      white-space: nowrap;
    }
  }
  &-Info {
    margin-top: 8px;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
