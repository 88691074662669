<template lang="pug">
LegendTable(
  :property="property"
  :data="data"
)
</template>

<script lang="ts">
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';
import LegendTable from '@/modules/map/container/MapLegend/LegendTable.vue';
import {
  computed, defineComponent, PropType, reactive, ref, watch,
} from 'vue';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';

export default defineComponent({
  name: 'UnifiedVectorLegend',
  components: { LegendTable },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    layer: {
      type: Object as PropType<MapLayerUnifiedVectorModel>,
      required: true,
    },
  },
  setup(props) {
    const property = computed(() => (props.layer.palette?.property));

    const data = ref<{ color: string, label: string }[]>([]);

    const calculateData = () => {
      if (props.layer.palette?.type === 'borderValues' && props.layer.palette.borderData) {
        data.value = props.layer.palette.borderData.values.map((_v) => ({ color: _v.color, label: _v.label }));
      }
      if (props.layer.palette?.type === 'uniqValues' && props.layer.palette.uniqData) {
        data.value = props.layer.palette.uniqData.values.map((_v) => ({ color: _v.color, label: _v.label }));
      }
    };

    LoadingStatus.awaitLoad(LoadingNamesEnum.ASSETS_VECTOR_PALETTE, props.layer.data.id).then(() => {
      calculateData();
    });

    watch(() => props.layer.palette, calculateData);

    return {
      property,
      data,
    };
  },
});
</script>

<style lang="scss" scoped>
.UnifiedVectorLegend {
  &-values {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
  }
  &-value {
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 10px;
    &-color {
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
    &-label {
      text-align: left;
    }
  }
}
</style>
