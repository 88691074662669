<template lang='pug'>
.form-header.mb-2 {{ $t('base-task-map', 'Базовая карта') }}
TaskMapSelector(
  v-if="activeField "
  :task-maps="taskMaps"
  v-model="baseTaskMap"
  :loading="loading"
  :map-container="MapContainerEnum.MAIN_MAP"
  :position="'left'"
)
</template>

<script lang='ts'>
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import {
  computed, defineComponent, onMounted, onUnmounted, ref, watch,
} from 'vue';
import TaskMapSelector from '@/components/shared/TaskMapSelector/TaskMapSelector.vue';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { MapLayerTaskMapBaseModel } from '@/models/map/Layers/MapLayerTaskMapBaseModel';
import PermissionsList from '@/modules/permissions/PermissionsList';

export default defineComponent({
  name: 'ExperimentSelectTaskMap',
  components: { TaskMapSelector },

  setup() {
    const {
      activeField,
      mapModel,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const baseTaskMap = ref<FieldTaskMapBaseModel>();
    const baseLayer = ref<MapLayerTaskMapBaseModel>();

    const taskMaps = computed(() => activeField.value?.baseTaskMaps.filter((v) => !v.isExperiment) || []);

    const loading = ref(false);

    watch(activeField, () => {
      baseTaskMap.value = undefined;
    });

    watch(activeField, () => {
      if (PermissionsList.hasPermission('basetaskmapexperiment', 'add', activeField.value?.id)) {
        LoadingStatus
          .awaitLoad(LoadingNamesEnum.FIELD_TASK_MAPS, activeField.value?.id || 0)
          .then(() => {
            if (taskMaps.value.length > 0 && baseTaskMap.value?.field !== activeField.value?.id) {
              baseTaskMap.value = taskMaps.value[0];
            }
            loading.value = false;
          });
        loading.value = true;
        activeField.value?.fetchTaskMaps();
      }
    });

    onMounted(() => {
      if (activeField.value && PermissionsList.hasPermission('basetaskmapexperiment', 'add', activeField.value?.id)) {
        LoadingStatus
          .awaitLoad(LoadingNamesEnum.FIELD_TASK_MAPS, activeField.value?.id || 0)
          .then(() => {
            if (taskMaps.value.length > 0 && baseTaskMap.value?.field !== activeField.value?.id) {
              baseTaskMap.value = taskMaps.value[0];
            }
            loading.value = false;
          });
        loading.value = true;
        activeField.value?.fetchTaskMaps();
      }
    });

    watch(baseTaskMap, (a) => {
      if (a !== undefined && PermissionsList.hasPermission('basetaskmapexperiment', 'add', activeField.value?.id)) baseLayer.value = mapModel.value.render(baseTaskMap.value) as MapLayerTaskMapBaseModel;
    });
    onUnmounted(() => {
      baseTaskMap.value = undefined;
      mapModel.value.removeLayer(baseLayer.value.uuid);
      baseLayer.value = undefined;
    });

    return {
      MapContainerEnum,
      activeField,
      taskMaps,
      loading,
      mapModel,
      baseTaskMap,
    };
  },
});
</script>
