<template lang='pug' src='./ImagesTree.pug'/>
<style lang='scss' src='./ImagesTree.scss'/>

<script lang="ts">
import { SliderMarks0to100 } from '@/assets/settings/SliderMarks0to100';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import UiLoading from '@/components/ui/Loading/UiLoading.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { FilesGroupType } from '@/constants/types/FilesGroupType';
import { FilesItemType } from '@/constants/types/FilesItemType';
import { ImageFileType } from '@/constants/types/ImageFileType';
import { ImageNodeType, ImageNodeTypes } from '@/constants/types/ImageNodeType';
import { MapLayerRasterModel } from '@/models/map/Layers/MapLayerRasterModel';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';
import { RasterModel } from '@/models/RasterModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import GroupPalettePanel
  from '@/modules/map/container/SidePanel/ImagesPanel/GroupPalettePanel/GroupPalettePanel.vue';
import RasterPalettePanel
  from '@/modules/map/container/SidePanel/ImagesPanel/RasterPalettePanel/RasterPalettePanel.vue';
import VectorPalettePanel
  from '@/modules/map/container/SidePanel/ImagesPanel/VectorPalettePanel/VectorPalettePanel.vue';
import StructFilesList from '@/modules/struct/StructFilesList';
import ApiService from '@/services/api/ApiService';
import { FilesGroupDto } from '@/services/api/dto/gis/FilesGroupDto';
import AssetsDefaultPalettes from '@/services/assets/AssetsDefaultPalettes';
import AssetsGradients from '@/services/assets/AssetsGradients';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { Loading } from '@element-plus/icons-vue';
import { ElTree } from 'element-plus';
import Node from 'element-plus/es/components/tree/src/model/node';
import type { AllowDropType, NodeDropType } from 'element-plus/es/components/tree/src/tree.type';
import {
  computed, defineComponent, onMounted, PropType, ref,
} from 'vue';
import { SocketMessageType } from '@/constants/types/SocketMessageType';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import EventBus from '@/services/eventBus/EventBus';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import StructList from '@/modules/struct/StructList';
import { useStruct } from '@/composables/useStruct';

export default defineComponent({
  name: 'ImagesTree',

  props: {
    group: {
      type: Object as PropType<FilesGroupType | FilesGroupDto>,
      required: true,
    },
    rootGroup: {
      type: Boolean,
      default: false,
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GroupPalettePanel,
    RasterPalettePanel,
    UiLoading,
    Loading,
    VectorPalettePanel,
    UiIcon,
    UiDrawer,
  },
  setup(props) {
    const image = (item: FilesItemType) => (item.type === 'vector' ? StructFilesList.vectors.value.find((v) => v.id === item.obj) as UnifiedVectorModel | undefined : StructFilesList.rasters.value.find((v) => v.id === item.obj) as RasterModel | undefined);

    const treeRef = ref<InstanceType<typeof ElTree>>();

    const activeNode = ref<ImageNodeType | undefined>();

    const renameDialogVisible = ref(false);

    const settings = ref<ImageNodeType | undefined>();

    const renameValue = ref('');

    const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;

    const loading = ref(true);

    const itemName = (item: ImageFileType): string => {
      if (item.type === 'vector') {
        return StructFilesList.vectors.value.find((v) => v.id === item.obj)?.name || 'name is undefined';
      } if (item.type === 'raster') {
        return StructFilesList.rasters.value.find((v) => v.id === item.obj)?.alias || 'alias is undefined';
      }
      return item.label || '';
    };

    const icon = (item: ImageNodeType) => {
      if (!item.model && item.type !== 'group' && item.type !== 'rating-fields') {
        return 'mdiAlertCircleOutline';
      }
      if (item.type === 'rating-fields') {
        return 'mdiAlphabeticalVariant';
      }
      if (item.type === 'vector') {
        return 'mdiFileCodeOutline';
      }
      if (item.type === 'raster') {
        return 'mdiFileImageOutline';
      }
      return 'mdiFolderOutline';
    };

    const iconColor = (item: ImageNodeType) => {
      if (!item.model && item.type !== 'group' && item.type !== 'rating-fields') {
        return '#a41212';
      }
      if (item.type === 'rating-fields') {
        return '#b0a21a';
      }
      if (item.type === 'vector') {
        return '#00966C';
      }
      if (item.type === 'raster') {
        return '#C66300';
      }
      return '#0036B9';
    };

    const convertType = (type: string) => {
      if (type.endsWith('_vector')) {
        return 'vector';
      }
      if (type.endsWith('_raster')) {
        return 'raster';
      }
      return 'group';
    };

    const getModel = (item: ImageFileType) => {
      switch (convertType(item.type)) {
      case 'vector':
        return StructFilesList.vectors.value.find((v) => v.id === item.obj) as UnifiedVectorModel | undefined;
      case 'raster':
        return StructFilesList.rasters.value.find((v) => v.id === item.obj) as RasterModel | undefined;
      default:
        return undefined;
      }
    };

    // @ts-ignore
    const recursiveGroup = (item: ImageFileType, path = ''): ImageNodeType => ({
      id: Number(item.id),
      key: `${item.id}-${item.type}`,
      active: false,
      obj: Number(item.obj),
      label: itemName(item),
      isLeaf: item.is_leaf,
      paletteId: item.palette,
      type: convertType(item.type) as ImageNodeTypes,
      originType: item.type,
      path,
      children: item.children.map((child) => recursiveGroup(child, `${path}/${item.id}-${item.type}`)) as ImageNodeType[],
      model: getModel(item) as RasterModel | UnifiedVectorModel | undefined,
      opacity: 100,
    });

    const data = ref<ImageNodeType[]>([] as ImageNodeType[]);

    const searchNode = (key: string, tree: ImageFileType[]): ImageFileType | undefined => {
      // eslint-disable-next-line no-restricted-syntax
      for (const node of tree) {
        if (`${node.id}-${node.type}` === key) {
          return node;
        }
        if (node.children) {
          const foundNode = searchNode(key, node.children);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return undefined;
    };

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.SERVICE_TOKEN, 'default');
      await AssetsGradients.fetch();
      await AssetsDefaultPalettes.fetch();
      const unit = useStruct().structId.value;
      await LoadingStatus.awaitLoad(LoadingNamesEnum.STRUCT_FILES_RASTERS, unit.toString());
      await LoadingStatus.awaitLoad(LoadingNamesEnum.STRUCT_FILES_VECTORS, unit.toString());
      await StructFilesList.fetchGroups();
      setTimeout(() => {
        data.value = [
          {
            id: 0,
            key: 'rating-fields',
            active: false,
            obj: 0,
            label: 'Рейтинг полей',
            isLeaf: true,
            paletteId: '',
            type: 'rating-fields',
            originType: 'rating-fields',
            path: '',
            children: [],
            model: undefined,
            opacity: 100,
          },
          ...StructFilesList.groups.value.map((g) => recursiveGroup(g)),
        ];
      }, 300);

      loading.value = false;
    });

    const flatData = computed<ImageNodeType[]>(() => {
      const result: ImageNodeType[] = [];
      const recursiveMap = (node: ImageNodeType) => {
        result.push(node);
        if (node.children) {
          node.children.forEach((child) => recursiveMap(child));
        }
      };
      treeRef.value?.data.forEach((n: ImageNodeType) => {
        recursiveMap(n);
      });
      return result;
    });

    const flatFiles = computed<ImageFileType[]>(() => {
      const result: ImageFileType[] = [];
      const recursiveMap = (node: ImageFileType) => {
        result.push(node);
        if (node.children) {
          node.children.forEach((child) => recursiveMap(child));
        }
      };
      StructFilesList.groups.value.forEach((n: ImageFileType) => {
        recursiveMap(n);
      });
      return result;
    });

    const isVisible = computed(() => (node: ImageNodeType) => {
      if (node.type !== 'group' && node.active) {
        return node.path.split('/')
          .filter(Boolean)
          .every((id) => {
            const parentNode = flatData.value.find((v) => v.key === id);
            return !!parentNode && parentNode.active;
          });
      }
      return node.active;
    });

    const allowDrop = (draggingNode: Node, dropNode: Node, type: AllowDropType) => {
      if (type === 'inner') {
        return !dropNode.data.isLeaf;
      }
      return true;
    };

    const orderLayers = () => {
      const layers = mapModel.value.allLayers.filter((l) => l instanceof MapLayerUnifiedVectorModel || l instanceof MapLayerRasterModel) as (MapLayerUnifiedVectorModel | MapLayerRasterModel)[];

      let layerId: string = MapAnchorEnum.TREE_IMAGES;
      flatData.value.forEach((n: ImageNodeType) => {
        if (n.type === 'group') return;
        const layer = layers.find((l) => l.treeKey === n.key);
        if (layer) {
          try {
            if (mapModel.value.map.getLayer(layer.layerId)) {
              mapModel.value.map.moveLayer(layer.layerId, layerId);
              layerId = layer.layerId;
            } else {
              const interval = setInterval(() => {
                if (mapModel.value.map.getLayer(layer.layerId)) {
                  clearInterval(interval);
                  mapModel.value.map.moveLayer(layer.layerId, layerId);
                  layerId = layer.layerId;
                }
              }, 50);
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
    };

    const render = async () => {
      const layers = mapModel.value.allLayers.filter((l) => l instanceof MapLayerUnifiedVectorModel || l instanceof MapLayerRasterModel) as (MapLayerUnifiedVectorModel | MapLayerRasterModel)[];
      let shouldReorder = false;

      const promises = flatData.value.map(async (n: ImageNodeType) => {
        if (n.type === 'rating-fields' && mapModel.value) {
          const fieldsLayer = (mapModel.value.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel);
          fieldsLayer.selectedPaint = n.active ? 'rating' : 'none';
          fieldsLayer.setOpacity(100);
          fieldsLayer.handlerFieldPaint();
        }
        if (!n.model || !mapModel.value) {
          return n;
        }

        const shouldShow = n.active && isVisible.value(n);
        const layer = layers.find((l) => l.treeKey === n.key);
        if (shouldShow && !layer) {
          treeRef.value && (treeRef.value.getNode(n.key).loading = true);
          const valid = await n.model.render(mapModel.value, n.key, n.label, n.paletteId);
          if (valid) {
            shouldReorder = true;
          } else {
            n.active = false;
          }
          treeRef.value && (treeRef.value.getNode(n.key).loading = false);
        }

        if (layer && !shouldShow) {
          mapModel.value.removeLayer(layer.uuid);
        }
        return n;
      });

      await Promise.all(promises);
      setTimeout(() => {
        if (shouldReorder) {
          orderLayers();
        }
      });
    };

    const activateParentGroups = (node: ImageNodeType) => {
      if (node.active) {
        node.path.split('/').forEach((id) => {
          const parentNode = flatData.value.find((v) => v.key === id);
          if (parentNode) parentNode.active = true;
        });
      }
    };

    const handleCheckbox = ($event: MouseEvent, node: ImageNodeType) => {
      $event.stopImmediatePropagation();

      node.active = !node.active;
      activateParentGroups(node);

      render();
    };

    const handleClick = (node: ImageNodeType) => {
      if (['raster', 'vector'].includes(node.type) && !!node.model) {
        node.active = !node.active;
        activateParentGroups(node);
      }
      if (node.type === 'rating-fields') {
        node.active = !node.active;
      }
      render();
    };

    const saveName = (node: ImageNodeType) => {
      ApiService.gis.patchGroup(node.key, {
        label: node.label,
      });
    };

    const deletePalette = (node: ImageNodeType) => {
      if (node) {
        ApiService.gis.deleteGroup(node.key);
      }
      if ((mapModel.value.allLayers as (MapLayerUnifiedVectorModel | MapLayerRasterModel)[]).find((v) => v.treeKey === node.key)) {
        mapModel.value.removeLayer(node.model.uuid);
      }
      settings.value = undefined;
    };

    const handleDrop = async (
      draggingNode: Node,
      dropNode: Node,
      dropType: NodeDropType,
    ) => {
      ApiService.gis.orderGroups({
        target: {
          obj: Number(draggingNode.data.id),
          type: draggingNode.data.originType,
        },
        position: {
          item: {
            obj: Number(dropNode.data.id),
            type: dropNode.data.originType,
          },
          drop_type: dropType,
        },
      });
      orderLayers();
    };

    const openSettings = (event: MouseEvent, node: ImageNodeType) => {
      event.stopImmediatePropagation();
      event.preventDefault();
      settings.value = node;
    };

    const setOpacity = (node: ImageNodeType) => {
      mapModel.value?.allLayers.filter((l) => l instanceof MapLayerUnifiedVectorModel || l instanceof MapLayerRasterModel).find((l: MapLayerUnifiedVectorModel | MapLayerRasterModel) => l.treeKey === node.key)?.setOpacity(node.opacity);
    };

    const resetPath = (id: number, type: string) => {
      const parents = [];
      const node = flatData.value.find((n) => n.id === id && n.originType === type);
      let _id = id;
      let _type = type;
      let parent = flatData.value.find((g) => g.children.some((n) => n.id === id && n.originType === type));
      while (parent) {
        parents.unshift(`${parent.id}-${parent.originType}`);
        _id = parent.id;
        _type = parent.originType;
        // eslint-disable-next-line no-loop-func
        parent = flatData.value.find((g) => g.children.some((n) => n.id === _id && n.originType === _type));
      }
      node.path = `/${parents.join('/')}`;
    };

    EventBus.$on(EventsEnum.SocketMessage, async (message: SocketMessageType) => {
      if (!message.unit || message.unit !== StructList.activeStruct.value.id) {
        return;
      }
      const payload = message.payload as {
        id: number,
        obj: number,
        parent: number | null,
        type: string,
      };
      if (message.event === 'GIS_GROUP_ITEM_DELETE') {
        const dataNode = flatData.value.find((n) => n.id === payload.id && n.originType === payload.type);
        if (dataNode.model?.uuid && mapModel.value.getLayer(dataNode.model.uuid)) {
          mapModel.value.removeLayer(dataNode.model?.uuid);
        }

        if (payload.parent) {
          const parentNode = flatData.value.find((v) => v.id === payload.parent && v.originType === 'group');
          if (parentNode) {
            parentNode.children.splice(parentNode.children.findIndex((v) => v.id === payload.id && v.originType === payload.type), 1);
          }
        } else {
          data.value.splice(data.value.findIndex((v) => v.id === payload.id && v.originType === payload.type), 1);
        }
      }
      if (['GIS_GROUP_ITEM_ADD', 'GIS_GROUP_ITEM_UPDATE', 'GIS_GROUP_ITEM_MOVE'].includes(message.event)) {
        await StructFilesList.fetchGroups(true);
        const file = flatFiles.value.find((v) => v.id === payload.id && v.type === payload.type);
        if (!file) {
          return;
        }

        if (message.event === 'GIS_GROUP_ITEM_ADD') {
          const node = flatFiles.value.find((v) => v.id === payload.id && v.type === payload.type);
          if (payload.parent) {
            const dataNode = flatData.value.find((v) => v.id === payload.parent && v.type === 'group');
            if (dataNode?.children) {
              const path = `${dataNode.path}/${dataNode.id}-${dataNode.type}`;
              dataNode.children.push(recursiveGroup(node as ImageFileType, path));
            }
          } else {
            data.value.push(recursiveGroup(node as ImageFileType, ''));
          }
        }

        if (message.event === 'GIS_GROUP_ITEM_UPDATE') {
          const node = flatData.value.find((v) => v.id === payload.id && v.originType === payload.type);
          if (node) {
            if (node.label !== file.label) {
              node.label = file.label;
            }
          }
        }

        if (message.event === 'GIS_GROUP_ITEM_MOVE') {
          const dataNode = { ...flatData.value.find((n) => n.id === payload.id && n.originType === payload.type) };
          treeRef.value.remove(treeRef.value.getNode(`${payload.id}-${payload.type}`));
          flatData.value.splice(flatData.value.findIndex((n) => n.id === payload.id && n.originType === payload.type));
          if (payload.parent) {
            const parentNode = flatData.value.find((g) => g.id === payload.parent && g.type === 'group');
            const position = flatFiles.value.find((g) => g.id === payload.parent && g.type === 'group').children.findIndex((n) => n.id === payload.id && n.type === payload.type);
            parentNode?.children.splice(position, 0, dataNode);
          } else {
            const position = StructFilesList.groups.value.findIndex((n) => n.id === payload.id && n.type === payload.type);
            data.value.splice(position + 1, 0, dataNode); // +1 добавляется из-за Рейтинга полей
          }
          setTimeout(() => {
            resetPath(payload.id, payload.type);
            orderLayers();
            activateParentGroups(flatData.value.find((n) => n.id === payload.id && n.originType === payload.type));
          }, 100);
        }
      }
    });

    return {
      SliderMarks0to100,
      loading,
      icon,
      iconColor,
      handleClick,
      treeRef,
      StructFilesList,
      image,
      handleDrop,
      renameValue,
      allowDrop,
      renameDialogVisible,
      data,
      activeNode,
      settings,
      UnifiedVectorModel,
      openSettings,
      setOpacity,
      saveName,
      deletePalette,
      handleCheckbox,
      isVisible,
    };
  },
});
</script>
