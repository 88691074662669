// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/auth/auth-agronote-min.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --transition-default: all 0.25s ease;\n  --transition-compareMap: top 0.25s ease, left 0.25s ease, right 0.25s ease, bottom 0.25s ease, width 0.25s ease, height 0.25s ease, opacity 0.25s ease;\n  font-size: 1rem;\n}\n\n.MapToolButton {\n  height: 40px;\n  width: 40px;\n  background: var(--color-dark-background);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 10px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n  cursor: pointer;\n  transition: var(--transition-default);\n  color: var(--color-dark-foreground);\n}\n.MapToolButton img {\n  width: 24px;\n  height: 24px;\n}\n.MapToolButton.active {\n  background: var(--color-dark-accent);\n}\n.MapToolButton:not(.active):hover {\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);\n  background: var(--color-dark-background-light);\n}\n\n.UnavailableLayout {\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n  box-shadow: 0px 6px 33px -7px #0F8764 inset;\n}\n.UnavailableLayout_agronote {\n  position: absolute;\n  left: 30vw;\n  right: 30vw;\n  top: 10vh;\n  bottom: 60vh;\n}\n.UnavailableLayout_agronote-image {\n  width: 100%;\n  height: 100%;\n  margin: 0 auto;\n  max-height: 672px;\n  max-width: 493px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n.UnavailableLayout_text {\n  width: 100%;\n  bottom: 20vh;\n  font-size: 3rem;\n  font-weight: 500;\n  margin: 50vh 0 0 0;\n  background: #ffdaa6;\n  padding: 1rem 0.5rem;\n  border-radius: 9px;\n  box-shadow: 0 0 60px 20px #ffdaa6;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
