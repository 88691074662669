<script lang="ts">
import {
  computed, defineComponent, PropType, ref, toRefs,
} from 'vue';
import { CreatingTaskMapModel } from '@/models/taskMap/CreatingTaskMapModel';
import { TaskMapMaterialTypeEnum } from '@/constants/enums/TaskMapMaterialTypeEnum';
import { formatNumber } from '@/lib/convertors/formatNumber';
import { TableColumnCtx } from 'element-plus';

export default defineComponent({
  name: 'TaskFieldsStats',
  props: {
    zones: {
      type: Array as PropType<CreatingTaskMapModel[]>,
      required: true,
    },
    materials: {
      type: Array as PropType<{
          norma: number,
          name: string,
          unit: string,
          type: TaskMapMaterialTypeEnum,
      }[]>,
      required: true,
    },
  },
  setup(props) {
    const isModel = ref(false);
    const { zones } = toRefs(props);

    const calculateZoneMaterial = (task: CreatingTaskMapModel): number => {
      const dp: number[] = [];

      task.zones.forEach((v) => {
        dp.push(v.area * (v.value || 0));
      });
      task.opts.forEach((v) => {
        dp.push(v.area * (v.value || 0));
      });
      return dp.reduce((acc, v) => acc + v, 0);
    };

    const tableData = computed(() => {
      const dp: {
        fieldName: string,
        material: string,
        norma: number,
        diff: number,
        area: number,
        delta: number,
        unit: string,
      }[] = [];

      zones.value.forEach((a) => {
        if (!dp.some((b) => b.fieldName === a.field.name && b.material === a.material.name)) {
          const zoneValue = calculateZoneMaterial(a);
          dp.push({
            fieldName: a.field.name,
            material: a.material.name,
            norma: (a.material.norma || 0) * a.baseMap.area,
            diff: zoneValue,
            area: a.baseMap.area,
            delta: zoneValue - (a.baseMap.area * (a.material.norma || 0)) || 0,
            unit: a.material.unit,
          });
        }
      });
      const groupedByFieldName = dp.reduce((acc, obj) => {
        // @ts-ignore
        if (!acc[obj.fieldName]) {
          // @ts-ignore
          acc[obj.fieldName] = {
            fieldName: obj.fieldName,
            materials: [],
            area: 0,
          };
        }
        // @ts-ignore
        acc[obj.fieldName].materials.push({
          materialName: obj.material,
          norma: obj.norma,
          diff: obj.diff,
          delta: obj.delta,
          unit: obj.unit,
        });
        // @ts-ignore
        acc[obj.fieldName].area = obj.area;
        return acc;
      }, {});

      return Object.values(groupedByFieldName);
    });
    interface Product {
      fieldName: string;
      area: number;
      materials: {
        materialName: string;
        norma: number;
        diff: number;
        delta: number;
        unit: string;
      }[]
    }
    interface SummaryMethodProps<T = Product> {
      columns: TableColumnCtx<T>[]
      data: T[]
    }
    const calculateSum = (param: SummaryMethodProps) => {
      const { columns, data } = param;
      const sums: string[] = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Cумма';
        } else if (index === 1) {
          const values = data.map((item) => Number(item.area));
          const sum = values.reduce((prev, curr) => prev + curr, 0);
          sums.push(formatNumber(sum));
        } else {
          const keys = column.property.split('.');
          // @ts-ignore
          const values = data.map((item) => item.materials.find((a) => a.materialName === keys[0])[keys[1]]);

          const sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!Number.isNaN(value)) {
              return prev + curr;
            }
            return prev;
          }, 0);
          sums.push(formatNumber(sum));
        }
      });
      return sums;
    };

    const getUnitLabel = computed(() => (v: string) => (['п.е', 'шт', 'тыс.шт'].includes(v) ? `${v}.` : v));
    return {
      isModel,
      tableData,
      formatNumber,
      calculateSum,
      getUnitLabel,
    };
  },
});
</script>

<template lang="pug">
el-button(
  link
  type="primary"
  @click="isModel = true"
) {{$t('stats-for-field',{ns:'taskMap'})}}
teleport(
  to="body"
)
  ui-dialog(
    v-model="isModel"
    :header="$t('stats-for-field-title-modal',{ns:'taskMap'})"
  )
    el-table(
      :data="tableData"
      :summary-method="calculateSum"
      show-summary
    )
      el-table-column(
        prop="fieldName"
        label="Поля"
        :min-width="150"
      )
      el-table-column(
       prop="area"
       label="Плошадь, га"
        :min-width="150"
      )
        template(#default="scope")
          div {{formatNumber(scope.row.area)}}
      el-table-column(
        v-for="m in materials"
        :label="`${m.name}, ${getUnitLabel(m.unit)}`"
      )
        el-table-column(
          :label="$t('stat-table-title-norma','Cплошное внесение нормой',{ns:'taskMap'})"
          :property="`${m.name}.norma`"
          :min-width="200"
        )
          template(#default="scope")
            div {{formatNumber(scope.row.materials.find(a=> a.materialName === m.name)?.norma)}}
        el-table-column(
          :label="$t('stat-table-title-diff','Дифф. внесение',{ns:'taskMap'})"
          :property="`${m.name}.diff`"
          :min-width="150"
        )
          template(#default="scope")
            div {{formatNumber(scope.row.materials.find(a=> a.materialName === m.name)?.diff)}}
        el-table-column(
          :label="$t('stat-table-title-delta','Дельта',{ns:'taskMap'})"
          :property="`${m.name}.delta`"
          :min-width="150"
        )
          template(#default="scope")
            div {{formatNumber(scope.row.materials.find(a=> a.materialName === m.name)?.delta)}}

</template>

<style scoped lang="scss">

</style>
