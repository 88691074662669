<template lang="pug">
.MapLegend(
  v-if="layers?.length > 0"
)
  .bg-block-white.flex-col.flex-col-align-starts
    el-select(
      v-model="selectedLayer"
    )
      template(
        v-for="layer in layers"
      )
        el-option(
          :label="layer.label"
          :value="layer.value"
        )
    .MapLegend-content.pt-10
      template(
        v-for="layer in layers"
      )
        UnifiedVectorLegend(
          v-if="layer.type === MapLayerTypeEnum.UNIFIED_VECTOR && layer.layer.uuid === selectedLayer"
          :mapContainer="mapContainer"
          :layer="layer.layer as MapLayerUnifiedVectorModel"
        )

        MonitoringLegend(
          v-if="[ MapLayerTypeEnum.MONITORING_INDEX, MapLayerTypeEnum.AVERAGE_INDEX ].some((a) => a === layer.type) && layer.layer.uuid === selectedLayer"
          :mapContainer="mapContainer"
          :layer="layer.layer as MapLayerIndexModel"
        )
</template>

<script lang="ts">
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapLayerIndexModel } from '@/models/map/Layers/MapLayerIndexModel';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';
import MonitoringLegend from '@/modules/map/container/MapLegend/MonitoringLegend.vue';
import UnifiedVectorLegend from '@/modules/map/container/MapLegend/UnifiedVectorLegend.vue';
import {
  computed, defineComponent, PropType, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'MapLegend',
  components: { MonitoringLegend, UnifiedVectorLegend },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
  },
  setup(props) {
    const { mapModel } = useMapContainers(props.mapContainer);

    const selectedLayer = ref<string | undefined>('');

    const select = (uuid: string) => {
      selectedLayer.value = uuid;
    };

    const layers = computed(() => {
      const seenNames = new Set<string>();
      return mapModel.value.allLayers
        .filter((a) => a.showLegend.value)
        .map((v) => {
          let label = v.layerName;
          if (v instanceof MapLayerUnifiedVectorModel) {
            label = (v as MapLayerUnifiedVectorModel).treeName;
          }
          if (v instanceof MapLayerIndexModel) {
            label = v.data?.name;
          }

          return {
            label,
            value: v.uuid,
            type: v.layerType,
            layer: v as MapLayerUnifiedVectorModel | MapLayerIndexModel,
          };
        }).filter((item) => {
          if (item.layer instanceof MapLayerIndexModel) {
            if (item.label && seenNames.has(item.label)) {
              return false;
            }
            seenNames.add(item.label);
          }
          return true;
        });
    });

    watch(layers, () => {
      if (layers.value.length > 0) {
        select(layers.value[layers.value.length - 1].value);
      }
      if (selectedLayer.value && !layers.value.some((l) => l.value === selectedLayer.value)) {
        if (layers.value.length > 0) {
          select(layers.value[layers.value.length - 1].value);
        } else {
          select(undefined);
        }
      }
    });

    return {
      MapLayerUnifiedVectorModel,
      MapLayerIndexModel,
      MapLayerTypeEnum,
      layers,
      select,
      selectedLayer,
    };
  },
});
</script>

<style lang="scss" scoped>
.MapLegend {
  max-height: 100%;
  height: 100%;
  overflow: auto;
  &-title {
    margin-bottom: 10px;
    width: 100%;
    font-weight: 500;
    font-size: 1.1rem;
  }
  &-content {
    margin: 0 -1rem;
    width: calc(100% + 2rem);
  }
}
</style>
