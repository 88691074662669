<template lang="pug">
.Navigation
  .Navigation_left
    NavigationLogo
    NavigationMenu
  .Navigation_center
    NavigationStructUnits
  .Navigation_right
    NavigationAccount

</template>

<script lang="ts">
import NavigationLogo from '@/modules/navigation/ui/logo/NavigationLogo.vue';
import NavigationMenu from '@/modules/navigation/ui/menu/NavigationMenu.vue';
import NavigationAccount from '@/modules/navigation/ui/account/NavigationAccount.vue';
import NavigationStructUnits from '@/modules/navigation/ui/structUnits/NavigationStructUnits.vue';
import NavigationAdmin from '@/modules/navigation/ui/NavigationAdmin/NavigationAdmin.vue';
import NavigationIntegrations
  from '@/modules/navigation/ui/NavigationIntegrations/NavigationIntegrations.vue';
import { useUser } from '@/composables/useUser';
import { computed, defineComponent } from 'vue';
import StructList from '@/modules/struct/StructList';

export default defineComponent({
  name: 'NavigationBlock',
  components: {
    NavigationIntegrations,
    NavigationStructUnits,
    NavigationMenu,
    NavigationLogo,
    NavigationAccount,
    NavigationAdmin,
  },

  setup() {
    const { user } = useUser();
    const detectHamburger = () => {
      const left = document.getElementsByClassName('.Navigation_left');
    };

    window.addEventListener('resize', detectHamburger);

    return {
      StructList,
      user,
    };
  },
});

</script>

<style lang="scss">
.Navigation {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
  }
  &_center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 10px;
  }
  &_right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
  }
}
</style>
