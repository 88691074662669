import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { DefaultPaletteType } from '@/constants/types/assets/DefaultPaletteType';
import { fetcher } from '@/lib/tools/fetcher';
import ApiService from '@/services/api/ApiService';
import { DefaultPaletteDto } from '@/services/assets/dto/DefaultPaletteDto';
import { reactive } from 'vue';
import { useStruct } from '@/composables/useStruct';

class AssetsDefaultPalettes {
  public data = reactive<DefaultPaletteType[]>([]);

  async fetch(force = false): Promise<void> {
    const unit = useStruct().structId.value;
    await fetcher(LoadingNamesEnum.ASSETS_DEFAULT_PALETTES, unit, force, async () => {
      const { data } = await ApiService.assets.defaultPalettes();
      this.data = data.map((v: DefaultPaletteDto) => v as DefaultPaletteType);
    });
  }
}

export default new AssetsDefaultPalettes();
