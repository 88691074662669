import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "TableInfo" }
const _hoisted_2 = { class: "TableInfo-container" }
const _hoisted_3 = {
  key: 0,
  class: "TableInfo-container-main"
}
const _hoisted_4 = {
  key: 0,
  class: "mb-8 pb-5",
  style: {"border-bottom":"solid 1.5px #2B3946"}
}
const _hoisted_5 = {
  class: "mb-3",
  style: {"font-size":"1.1rem","font-weight":"500"}
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "TableInfo-body-item-params"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "TableInfo-body-item-params-leve2 pl-14" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 1,
  class: "TableInfo-body"
}
const _hoisted_12 = { class: "TableInfo-body-item" }
const _hoisted_13 = { class: "TableInfo-body-item-map" }
const _hoisted_14 = {
  key: 0,
  class: "mb-8 pb-5",
  style: {"border-bottom":"solid 1.5px #2B3946"}
}
const _hoisted_15 = {
  class: "mb-3",
  style: {"font-size":"1.1rem","font-weight":"500"}
}
const _hoisted_16 = {
  key: 0,
  class: "TableInfo-body-item-params"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "TableInfo-body-item-params-leve2 pl-14" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 1,
  class: "TableInfo-body-item-NoData"
}
const _hoisted_21 = { class: "TableInfo-body-item" }
const _hoisted_22 = { class: "TableInfo-body-item-map right" }
const _hoisted_23 = {
  key: 0,
  class: "mb-8 pb-5",
  style: {"border-bottom":"solid 1.5px #2B3946"}
}
const _hoisted_24 = {
  class: "mb-3",
  style: {"font-size":"1.1rem","font-weight":"500"}
}
const _hoisted_25 = {
  key: 0,
  class: "TableInfo-body-item-params"
}
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "TableInfo-body-item-params-leve2 pl-14" }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = {
  key: 1,
  class: "TableInfo-body-item-NoData"
}

export function render(_ctx, _cache) {
  const _component_ui_dialog = _resolveComponent("ui-dialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_dialog, {
      modelValue: _ctx.openDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.openDialog) = $event)),
      header: "Данные по слоям",
      onClose: _ctx.closeDialog
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.useMapContainers(_ctx.mapContainer).compareMode.value === 'single')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calculateDataMainMap, (value1) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (value1.layer?.metadata?.type !== undefined)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t((value1.layer?.metadata?.type || 'none'),{ns:'mapbox'})), 1 /* TEXT */),
                          (_ctx.isStaff)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("div", null, "LayerID: " + _toDisplayString(value1.layer.id), 1 /* TEXT */)
                              ]))
                            : _createCommentVNode("v-if", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value1.properties, (value, name, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (!(['uuid', 'create_date', 'update_date', 'id'].includes(name) || name.startsWith('__')))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    ('prod' === name)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                          _createElementVNode("span", null, _toDisplayString(name) + ":", 1 /* TEXT */),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(value), (v1) => {
                                            return (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v1, (v2, n2) => {
                                                return (_openBlock(), _createElementBlock("div", null, [
                                                  _createElementVNode("span", null, _toDisplayString(n2) + ": " + _toDisplayString(v2), 1 /* TEXT */)
                                                ]))
                                              }), 256 /* UNKEYED_FRAGMENT */))
                                            ]))
                                          }), 256 /* UNKEYED_FRAGMENT */))
                                        ]))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(name) + " " + _toDisplayString(!['uuid','create_date','update_date','id'].some( (n) => n === name ) ?? name.startsWith('__')) + ": " + _toDisplayString(value), 1 /* TEXT */))
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ], 64 /* STABLE_FRAGMENT */))
                          }), 256 /* UNKEYED_FRAGMENT */))
                        ]))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 256 /* UNKEYED_FRAGMENT */))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('left-map-1',{ns:'context-menu'})), 1 /* TEXT */)
                  ]),
                  (_ctx.calculateData0 !== undefined )
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.calculateData0, (value1, name, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (value1.layer?.metadata?.type !== undefined)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t((value1.layer?.metadata?.type || 'none'),{ns:'mapbox'})), 1 /* TEXT */),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value1.properties, (value, name, index) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                                    (!(['uuid', 'create_date', 'update_date', 'id'].includes(name) || name.startsWith('__')))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                          ('prod' === name)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                _createElementVNode("span", null, _toDisplayString(name) + ": " + _toDisplayString(1), 1 /* TEXT */),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(value), (v1) => {
                                                  return (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v1, (v2, n2) => {
                                                      return (_openBlock(), _createElementBlock("div", null, [
                                                        _createElementVNode("span", null, _toDisplayString(n2) + ": " + _toDisplayString(v2), 1 /* TEXT */)
                                                      ]))
                                                    }), 256 /* UNKEYED_FRAGMENT */))
                                                  ]))
                                                }), 256 /* UNKEYED_FRAGMENT */))
                                              ]))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(name) + ": " + _toDisplayString(value), 1 /* TEXT */))
                                        ]))
                                      : _createCommentVNode("v-if", true)
                                  ], 64 /* STABLE_FRAGMENT */))
                                }), 256 /* UNKEYED_FRAGMENT */))
                              ]))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                      }), 256 /* UNKEYED_FRAGMENT */))
                    : (_openBlock(), _createElementBlock("div", _hoisted_20, "NoData"))
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('right-map-2',{ns:'context-menu'})), 1 /* TEXT */)
                  ]),
                  (_ctx.calculateData0 !== undefined)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.calculateDataMainMap, (value1, name, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (value1.layer?.metadata?.type !== undefined)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t((value1.layer?.metadata?.type || 'none'),{ns:'mapbox'})), 1 /* TEXT */),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value1.properties, (value, name, index) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                                    (!(['uuid', 'create_date', 'update_date', 'id'].includes(name) || name.startsWith('__')))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                          ('prod' === name)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                                _createElementVNode("span", null, _toDisplayString(name) + ": " + _toDisplayString(2), 1 /* TEXT */),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(value), (v1) => {
                                                  return (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v1, (v2, n2) => {
                                                      return (_openBlock(), _createElementBlock("div", null, [
                                                        _createElementVNode("span", null, _toDisplayString(n2) + ": " + _toDisplayString(v2), 1 /* TEXT */)
                                                      ]))
                                                    }), 256 /* UNKEYED_FRAGMENT */))
                                                  ]))
                                                }), 256 /* UNKEYED_FRAGMENT */))
                                              ]))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(name) + ": " + _toDisplayString(value), 1 /* TEXT */))
                                        ]))
                                      : _createCommentVNode("v-if", true)
                                  ], 64 /* STABLE_FRAGMENT */))
                                }), 256 /* UNKEYED_FRAGMENT */))
                              ]))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                      }), 256 /* UNKEYED_FRAGMENT */))
                    : (_openBlock(), _createElementBlock("div", _hoisted_29, "NoData"))
                ])
              ]))
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "onClose"])
  ]))
}