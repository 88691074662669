<template lang="pug">
.VectorPalettePanel-blocks(
  v-if="layer.palette && paletteData?.classCount"
)
  .VectorPalettePanel-block
    el-form-item(
      :label="$t('select-class-count', { ns: 'mapbox' })"
    )
      el-input-number(
        v-model="paletteData.classCount"
        :min="3"
        :value-on-clear="3"
        :max="Math.min(20, (layer.palette?.values as number[]).length)"
      )
  .VectorPalettePanel-block
    UiHistogram(
      v-if="layer.palette?.values"
      :values="values"
      :height="220"
      :classes="paletteData.values"
      :accuracy="layer.palette?.range.diff"
    )
    UiSlider(
      v-model="histogramSlider"
      :min="layer.palette?.range.min"
      :max="layer.palette?.range.max"
      :precision="precision"
    )
  .VectorPalettePanel-block
    .VectorPalettePanel-classes
      .VectorPalettePanel-class.header
        .VectorPalettePanel-class-colorBox {{ $t('color') }}
        .VectorPalettePanel-class-value {{ $t('value') }}
        .VectorPalettePanel-class-label {{ $t('label') }}

      .VectorPalettePanel-class(
        v-for="(c, idx) in layer.palette?.borderData.values"
      )
        .VectorPalettePanel-class-colorBox
          color-picker(
            v-model:pureColor="c.color"
            blur-close
            :round-history="true"
            :format="'hex8'"
            :picker-type="'chrome'"
            :use-type="'pure'"
          ) {{ c.color?.toLocaleUpperCase() }}

        .VectorPalettePanel-class-value(
          v-if="c.range"
        ) {{ c.range.from }} - {{ c.range.to }}

        .VectorPalettePanel-class-label(
          v-if="c.label"
        )
          el-input(
            v-model="c.label"
          )
  .VectorPalettePanel-block
    PaletteGradientColorPicker(
      :palette="layer.palette"
    )
</template>

<script lang="ts">
import UiHistogram from '@/components/ui/Histogram/UiHistogram.vue';
import PaletteGradientColorPicker
  from '@/components/ui/PaletteGradientColorPicker/PaletteGradientColorPicker.vue';
import UiSlider from '@/components/ui/Slider/UiSlider.vue';
import { BorderDataType } from '@/constants/types/palette/UnifiedVectorPaletteType';
import { GradientModel } from '@/models/assets/GradientModel';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import AssetsGradients from '@/services/assets/AssetsGradients';
import {
  computed, defineComponent, PropType, reactive, watch,
} from 'vue';
import { ColorPicker } from 'vue3-colorpicker';

export default defineComponent({
  components: {
    PaletteGradientColorPicker,
    UiSlider,
    UiHistogram,
    ColorPicker,
  },
  props: {
    file: {
      type: Object as PropType<UnifiedVectorModel>,
      required: true,
    },
    layer: {
      type: Object as PropType<MapLayerUnifiedVectorModel | undefined>,
      required: true,
    },
  },
  setup(props) {
    const vector = reactive<UnifiedVectorModel>(props.file);

    const paletteData = reactive<BorderDataType>(props.layer.palette?.borderData as BorderDataType);

    const activeGradient = computed<GradientModel | undefined>(() => AssetsGradients.data.find((m) => m.id === paletteData.gradient?.id) as GradientModel | undefined);

    const histogramSlider = computed({
      get: () => [...paletteData.values.map((p) => p.range.from), paletteData.values[paletteData.values.length - 1].range.to] || [],
      set: (_stops: number[]) => {
        props.layer.palette?.updateBorderValues(_stops);
      },
    });

    watch(() => paletteData.classCount, (a) => {
      props.layer.palette?.calculateBorderValues();
    });
    const precision = computed(() => (props.layer.palette?.property ? vector.precision[props.layer.palette.property] : 0));

    const values = computed(() => (props.layer.palette?.property && props.layer.palette.values[props.layer.palette.property] ? props.layer.palette.values[props.layer.palette.property] : []));

    // const classes = computed(() => {
    //   const result = [];
    //
    //   for (let i = 0; i <= (paletteData.classCount - 1); i++) {
    //     const from = i === 0 ? minMaxValue.value.min : paletteData.histogram[i - 1];
    //     const to = paletteData.histogram[i];
    //     const color = paletteData.values[i]?.color || '#FF0000';
    //     result.push({
    //       value: 0,
    //       count: 0,
    //       color,
    //       range: { from, to },
    //     });
    //   }
    //   return result;
    // });

    watch(() => paletteData.classCount, () => {
      props.layer.palette?.calculateBorderValues();
    });

    const setGradientColors = (colors: string[]) => {
      console.log(colors);
    };

    return {
      precision,
      values,
      histogramSlider,
      vector,
      paletteData,
      activeGradient,
      setGradientColors,
    };
  },
});
</script>

<style lang="scss" scoped>
.VectorPalettePanel {
  .vc-color-wrap {
    width: 30px;
  }
  &-class {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 5px;
    margin-bottom: 5px;
    &.head {
      background: #F0F0F0;
      &-colorBox {
        font-weight: 500;
      }
      &-value {
        font-weight: 500;
      }
      &-label {
        font-weight: 500;
      }
    }
    &-colorBox {
      display: block;
      width: 40px;
    }
    &-value {
      flex-grow: 1;
      white-space: nowrap;
      text-align: center;
      font-weight: 500;
    }
    &-label {
      width: 120px;
    }
  }
}

</style>
