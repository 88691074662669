<template lang="pug">
template(
  v-if="paletteData.values.length <= MAX_UNIQUE_VALUES"
)
  .mb-20
    PaletteGradientColorPicker(
      :palette="layer.palette"
      @colors="setPickerColors"
    )

  .VectorPalettePanel-unique.header
    div
    div Значение
    div Подпись
  .VectorPalettePanel-unique(
    v-for="row in paletteData.values"
  )
    .VectorPalettePanel-unique-colorPicker
      color-picker(
        :pure-color="row.color"
        @pureColorChange="onColorChange($event, row)"
        :round-history="true"
        :format="'hex8'"
        :picker-type="'chrome'"
        :use-type="'pure'"
      )
    .VectorPalettePanel-unique-key {{ row.value }}
    .VectorPalettePanel-unique-legend
      el-input(
        v-model="row.label"
      )
template(
  v-else
)
  .VectorPalettePanel-message {{ $t('unique-palette-is-not-available', `Раскраска по уникальным значениям не доступна, количество уникальных значений более: `, { ns: 'vector-palette' }) }}
    span {{ MAX_UNIQUE_VALUES }}
</template>

<script lang="ts">
import PaletteGradientColorPicker
  from '@/components/ui/PaletteGradientColorPicker/PaletteGradientColorPicker.vue';
import { MAX_UNIQUE_VALUES } from '@/constants/constants/constants';
import { UniqDataType } from '@/constants/types/palette/UnifiedVectorPaletteType';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import { defineComponent, PropType, reactive } from 'vue';

export default defineComponent({
  name: 'UniqValuesTab',
  components: { PaletteGradientColorPicker },
  props: {
    file: {
      type: Object as PropType<UnifiedVectorModel>,
      required: true,
    },
    layer: {
      type: Object as PropType<MapLayerUnifiedVectorModel | undefined>,
      required: true,
    },
  },
  setup(props) {
    const paletteData = reactive<UniqDataType>(props.layer.palette?.uniqData as UniqDataType);

    const onColorChange = (event: string, row: {value: string | number; color: string; label: string}) => {
      if (event.endsWith('ff')) {
        row.color = event.slice(0, 7).toUpperCase();
      } else {
        row.color = event.toUpperCase();
      }
    };

    const setPickerColors = (colors: string[]) => {
      console.log(colors);
    };

    return {
      MAX_UNIQUE_VALUES,
      onColorChange,
      paletteData,
      setPickerColors,
    };
  },
});
</script>

<style lang="scss" scoped>

</style>
